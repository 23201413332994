
try {

    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default; // default is very important.
    require('bootstrap');

    const fancybox = require('@fancyapps/fancybox');

} catch (e) {}

import Swiper, {Navigation, Pagination, Lazy, Autoplay} from 'swiper';
Swiper.use([Pagination, Lazy, Navigation, Autoplay]);

import 'jquery-smooth-scroll/jquery.smooth-scroll';
import 'jquery-match-height/dist/jquery.matchHeight';
import 'js-offcanvas/dist/_js/js-offcanvas.pkgd';

$(document).ready(function(){

    $(window).scroll(function() {
        var offset = $(window).scrollTop();

        if(offset > 0) {
            $('body').addClass('scroll-offset');
        } else {
            $('body').removeClass('scroll-offset');
        }

    });

    $('*[data-smooth-scroll]').smoothScroll({
        speed: 'auto',
        beforeScroll: function(){
            offcanvas.close();
        }
    });

    if($(".product-swiper").length) {
        var surrCarousel = new Swiper('.product-swiper .swiper-container', {
            slidesPerView: '1',
            spaceBetween: 31,
            speed: 700,
            // autoplay: {
            //     delay: 7000,
            // },
            clickable: true,
            loop: true,
            watchOverflow: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            breakpoints: {
                640: {
                slidesPerView: 2,
                },
                992: {
                slidesPerView: 2,
                },
                1024: {
                slidesPerView: 3,
                },
            }
        });
    }
    if($(".masthead-swiper").length) {
        var surrCarousel = new Swiper('.masthead-swiper .swiper-container', {
            slidesPerView: '1',
            speed: 800,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            loop: true, 
            navigation: {
                prevEl: '.page-section--masthead-slider .swiper-button-prev',
                nextEl: '.page-section--masthead-slider .swiper-button-next',
            },
        });
    }


});

$('#offCanvas').offcanvas({
    modifiers: 'right, overlay', // default options
    triggerButton: '#triggerButton' // btn to open offcanvas
});
